import styles from './home.module.scss';

const HomePage = () => {
  return (
    <section>
      <div className={styles.imgWrapper}>
        <img src="/images/mark.png" alt="Mark" />
      </div>
    </section>
  );
};

export default HomePage;
